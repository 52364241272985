<template>
  <div class="pucontainer">
    <div class="back">
      <span class="iconfont icon-jtback" @click="$router.go(-1)">{{
        $t('common.back')
      }}</span>
    </div>
    <h5>
      {{ $route.query.title ? $route.query.title : $t('login_mod.forgot_pwd') }}
    </h5>
    <q-form @submit="onSubmit">
      <div class="infoCon">
        <div class="inputdiv" v-if="change">
          <q-input
            maxlength="11"
            type="tel"
            v-model="form.phone"
            autofocus
            :label="$t('login_mod.phone_t')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_phone'),
              (val) => phoneRule(val) || $t('login_mod.phone_format')
            ]"
          />
          <p>
            {{ $t('login_mod.ro_t') }}
            <span class="blod" @click="change = false">{{
              $t('login_mod.email_t')
            }}</span>
          </p>
        </div>
        <div class="inputdiv" v-else>
          <q-input
            autofocus
            maxlength="30"
            type="email"
            v-model="form.email"
            :label="$t('login_mod.email_t')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_email'),
              (val) => emailRule(val) || $t('login_mod.email_format')
            ]"
          />
          <p>
            {{ $t('login_mod.ro_t') }}
            <span class="blod" @click="change = true">{{
              $t('login_mod.phone_t')
            }}</span>
          </p>
        </div>
        <div class="inputdiv">
          <q-input
            type="text"
            maxlength="6"
            v-model="form.code"
            :label="$t('login_mod.verification_code')"
            :rules="[
              (val) =>
                (val !== null && val !== '') ||
                $t('login_mod.enter_verification_code')
            ]"
          >
            <template v-slot:append>
              <q-btn
                :loading="SendCodeLoading"
                :disable="SendCodeDisabled"
                color="primary"
                :label="$t('login_mod.send_verification_code') + time"
                @click="sendCode()"
              />
            </template>
          </q-input>
          <!-- <p>有效时间为60秒</p> -->
        </div>
        <h5>{{ $t('login_mod.reset_pwd') }}</h5>
        <div class="inputdiv">
          <q-input
            maxlength="30"
            type="password"
            v-model="form.pwd"
            :label="$t('login_mod.pwd_t')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_pwd'),
              (val) => pwdRule(val) || $t('login_mod.pwd_format')
            ]"
          />
        </div>
        <div class="inputdiv">
          <q-input
            maxlength="30"
            type="password"
            v-model="form.pwdAgain"
            :label="$t('login_mod.re_pwd')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_pwd'),
              (val) => form.pwd === form.pwdAgain || $t('login_mod.no_one_pwd')
            ]"
          />
        </div>
        <div class="infoBtn">
          <q-btn
            type="submit"
            class="full-width"
            color="primary"
            :label="$t('common.submit')"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { pwdRule, emailRule, phoneRule } from '@/common/formRules'
import { phoneChangePwdApi, emailChangePwdApi } from '@/api/login'
export default {
  data () {
    return {
      form: {
        phone: '',
        email: '',
        code: '',
        pwd: '',
        pwdAgain: ''
      },
      change: true,
      time: '',
      SendCodeLoading: false,
      SendCodeDisabled: false
    }
  },
  methods: {
    async onSubmit () {
      if (this.change) {
        const res = await phoneChangePwdApi({
          mark: this.form.phone,
          code: this.form.code,
          pwd: this.form.pwd
        })
        if (res.status === 3) {
          this.$router.go(-1)
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      } else {
        const res = await emailChangePwdApi({
          mark: this.form.email,
          code: this.form.code,
          pwd: this.form.pwd
        })
        if (res.status === 3) {
          this.$router.go(-1)
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      }
    },
    // 发送验证码
    async sendCode () {
      if (this.SendCodeLoading || this.SendCodeDisabled) {
        return
      }
      this.SendCodeLoading = true
      if (this.change) {
        if (this.form.phone === '' || !phoneRule(this.form.phone)) {
          this.$q.notify(this.$t('login_mod.phone_format'))
          this.SendCodeLoading = false
          return
        }
        const res = await phoneChangePwdApi({
          mark: this.form.phone
        })
        if (res.status === 1) {
          this.timerStart()
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      } else {
        if (this.form.email === '' || !emailRule(this.form.email)) {
          this.$q.notify(this.$t('login_mod.email_format'))
          this.SendCodeLoading = false
          return
        }
        const res = await emailChangePwdApi({
          mark: this.form.email
        })
        if (res.status === 1) {
          this.timerStart()
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      }
      this.SendCodeLoading = false
    },
    // 倒计时开始
    timerStart () {
      if (this.SendCodeDisabled) {
        return
      }
      this.SendCodeDisabled = true
      let timer = null
      this.time = 60
      timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearTimeout(timer)
          this.time = ''
          this.SendCodeDisabled = false
        }
      }, 1000)
    }
  },
  setup () {
    return {
      pwdRule,
      emailRule,
      phoneRule
    }
  }
}
</script>

<style lang="scss" scoped>
@import './puinput.scss';
</style>
